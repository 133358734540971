// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState } from "framer";

const cycleOrder = ["FV3Aeos8d", "b7xHpwPgO"];

const variantClassNames = {FV3Aeos8d: "framer-v-186eq8k", b7xHpwPgO: "framer-v-aazqy9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Burger: "FV3Aeos8d", X: "b7xHpwPgO"};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 5, delay: 0, stiffness: 400, damping: 30, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "FV3Aeos8d", tap: TBgHLr7MW, color: iWF8EWIN1 = "var(--token-0c3f76d2-28b9-4470-a1ca-46330b36daa2, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "FV3Aeos8d", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant);

const onTap186eq8k = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("b7xHpwPgO")
})

const onTapaazqy9 = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("FV3Aeos8d")
})

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-SqBWO", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-186eq8k", className)} style={{...style}} layoutId={"FV3Aeos8d"} transition={transition} layoutDependency={layoutDependency} background={null} data-highlight={true} data-framer-name={"Burger"} onTap={onTap186eq8k} ref={ref} {...addPropertyOverrides({b7xHpwPgO: {onTap: onTapaazqy9, "data-framer-name": "X", "data-highlight": true}}, baseVariant, gestureVariant)}><motion.div className={"framer-nc96r1"} style={{}} layoutId={"wkTfQPBfr"} transition={transition} layoutDependency={layoutDependency} background={null}><motion.div className={"framer-eebjcm"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} layoutId={"Ct4D8gJmB"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Bottom"} variants={{b7xHpwPgO: {rotate: -45}}} {...addPropertyOverrides({b7xHpwPgO: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1jb1wjq"} style={{backgroundColor: iWF8EWIN1, rotate: 0, opacity: 1}} layoutId={"QZuNED6b4"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Mid"} variants={{b7xHpwPgO: {rotate: 0, opacity: 0}}} {...addPropertyOverrides({b7xHpwPgO: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-7o3eu2"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} layoutId={"eD8Bub8WT"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Top"} variants={{b7xHpwPgO: {rotate: 45}}} {...addPropertyOverrides({b7xHpwPgO: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-SqBWO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SqBWO * { box-sizing: border-box; }", ".framer-SqBWO .framer-186eq8k { position: relative; cursor: pointer; overflow: hidden; width: 32px; height: 32px; }", ".framer-SqBWO .framer-nc96r1 { position: absolute; overflow: visible; width: 24px; height: 18px; left: calc(50.00000000000002% - 24px / 2); top: calc(50.00000000000002% - 18px / 2); flex: none; }", ".framer-SqBWO .framer-eebjcm { position: absolute; overflow: hidden; height: 2px; right: 0px; bottom: 0px; left: 0px; flex: none; }", ".framer-SqBWO .framer-1jb1wjq { position: absolute; overflow: hidden; height: 2px; right: 0px; left: 0px; top: calc(50.00000000000002% - 2px / 2); flex: none; }", ".framer-SqBWO .framer-7o3eu2 { position: absolute; overflow: hidden; height: 2px; right: 0px; left: 0px; top: 0px; flex: none; }", ".framer-SqBWO.framer-v-aazqy9 .framer-186eq8k { cursor: pointer; }", ".framer-SqBWO.framer-v-aazqy9 .framer-eebjcm { width: auto; height: 2px; right: 0px; bottom: auto; left: 0px; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }", ".framer-SqBWO.framer-v-aazqy9 .framer-1jb1wjq { width: 2px; height: 2px; right: 0px; bottom: auto; left: auto; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }", ".framer-SqBWO.framer-v-aazqy9 .framer-7o3eu2 { width: 24px; height: 2px; right: 0px; bottom: auto; left: auto; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"b7xHpwPgO":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const FramergZekuylps: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramergZekuylps;

FramergZekuylps.displayName = "Elements/Menu Icon Copy";

FramergZekuylps.defaultProps = {width: 32, height: 32};

addPropertyControls(FramergZekuylps, {variant: {type: ControlType.Enum, title: "Variant", options: ["FV3Aeos8d", "b7xHpwPgO"], optionTitles: ["Burger", "X"]}, TBgHLr7MW: {type: ControlType.EventHandler, title: "Tap"}, iWF8EWIN1: {type: ControlType.Color, title: "Color", defaultValue: "var(--token-0c3f76d2-28b9-4470-a1ca-46330b36daa2, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"}} as any);

addFonts(FramergZekuylps, []);